<template>
	<div>
		<CCard class="p-4">
			<CRow>

				<CCol lg="6">
					<h5>Asset Renewal Reminders ({{ dataListAll.total }})</h5>
				</CCol>
				<CCol lg="6">
					<CButton size="sm" style="float: right;"
						:class="'float-lg-right block ' + showCollapse ? 'collapsed' : null"
						:aria-expanded="showCollapse ? 'true' : 'false'" aria-controls="collapse1"
						@click="showCollapse = !showCollapse" color="info"><i class="fa fa-search"></i>Filter<i
							:class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i></CButton>
					<CButton v-if="config.getPermission('renewal_reminder').create" size="sm" style="margin-right: 10px"
						color="success" @click="toggleModal()" class="float-lg-right"><i class="fa fa-plus"></i>Add Renewal
						Reminder
					</CButton>

				</CCol>
			</CRow>
			<CRow>
				<CCol lg="12">
					<CCollapse id="collapse1" :show="showCollapse" class="mt-2">
						<CCard class="p-4">
							<Search @depot-search-query="search" />
						</CCard>
					</CCollapse>
				</CCol>
			</CRow>
			<hr>

			<CRow>

				<CCol lg="12">
					<div class="table-responsive-sm table-responsive-md">
						<CDataTable :loading="isLoading" :items="dataListAll.data" :fields="fields" hover striped outlined>
							<template #asset_type="{ item }">
								<td style="text-transform:capitalize;">
									{{ item.asset_type }}
								</td>
							</template>
							<template #status="{ item }">
								<td style="padding: 5px; vertical-align: inherit;">
									<CButton size="sm" style="width: 74px;"
										:color="item.status | colorStatus">
										{{ item.status }}
									</CButton>
								</td>
							</template>
							<template #renewal_task_id_label="{ item }">
								<td style="text-transform:capitalize;">
									{{ item.renewal_task_id_label }} <br>

								</td>
							</template>
							<template #due_date_label="{ item }">
								<td style="padding: 5px;">
									{{ item.due_date_label }} <br>
									<CBadge v-if="item.renewal_badge != null" :color="item.status | colorStatus">
										<small>{{ item.renewal_text }}</small>
									</CBadge>
								</td>
							</template>
							<template #action="{ item }">
								<td style="padding: 5px; width: 100px">
									<CDropdown toggler-text="Select" size="sm" color="info">
										<CDropdownItem v-if="config.getPermission('renewal_reminder').view"
											@click="viewModal(item)"><i class="fa fa-eye"> View </i></CDropdownItem>
										<CDropdownItem v-if="config.getPermission('renewal_reminder').update"
											@click="updateModal(item)"><i class="fa fa-pencil-square-o"> Update </i>
										</CDropdownItem>
										<CDropdownItem @click="sendEmail(item)">
											<div>
												<div><i class="fa fa-bell"> Notify </i></div>
												<!-- <CBadge block class="block text-white" color="warning">{{ item.notify_date }}
												</CBadge> -->
											</div>
										</CDropdownItem>
										<CDropdownItem v-if="config.getPermission('renewal_reminder').delete"
											@click="deleteRow(item)"><i class="fa fa-trash"> Delete </i></CDropdownItem>
									</CDropdown>
								</td>
							</template>
						</CDataTable>
						<CPagination :active-page.sync="currentPageAll" :pages="Math.ceil(dataListAll.total / 20)"
							:activePage="currentPageAll" @update:activePage="updatePageAll" />

					</div>

				</CCol>
			</CRow>


			<!-- CREATE/UPDATE -->
			<CModal :title="'Asset Renewal Reminder'" :show.sync="formModal" color="info" size="md">
				<form @submit.prevent="submit">

					<CRow>
						<CCol lg="8">
							<div class="form-group">
								<label for="asset_type">Asset Type<span class="text-danger"> *</span>
								</label>
								<CRow>
									<CCol lg="4">
										<CInputRadioGroup :options="[
											{
												value: 'vehicle',
												label: 'Vehicle'
											}

										]" :checked.sync="dataParams.asset_type" :inline="true" required="true" />
									</CCol>
									<CCol lg="4">
										<CInputRadioGroup :options="[
											{
												value: 'equipment',
												label: 'Equipment'
											}

										]" :checked.sync="dataParams.asset_type" :inline="true" required="true" />
									</CCol>
									<CCol lg="4">
										<CInputRadioGroup :options="[
											{
												value: 'furnishing',
												label: 'Furnishing'
											}

										]" :checked.sync="dataParams.asset_type" :inline="true" required="true" />
									</CCol>
								</CRow>
							</div>
						</CCol>
					</CRow>
					<CRow>
						<CCol lg="12">
							<label v-if="dataParams.asset_type !== 'vehicle'">Asset No. <span
								class="text-danger">*</span>
							</label>
							<label v-else>
								Plate No. <span class="text-danger">*</span>		
							</label>
							<v-select label="asset_no" :options="furnishingList.data" :reduce="item => item.id"
								placeholder="-Select-" v-if="dataParams.asset_type == 'furnishing'"
								v-model="dataParams.furnishing_id">
								<template #search="{ attributes, events }" v-if="dataParams.asset_type == 'furnishing'">
									<input class="vs__search"
										:required="!dataParams.furnishing_id && dataParams.asset_type == 'furnishing'"
										v-bind="attributes" v-on="events" />
								</template>
							</v-select>
							<v-select label="asset_no" :options="equipmentList.data" :reduce="item => item.id"
								placeholder="-Select-" v-model="dataParams.equipment_id"
								v-if="dataParams.asset_type == 'equipment'">
								<template #search="{ attributes, events }" v-if="dataParams.asset_type == 'equipment'">
									<input class="vs__search"
										:required="!dataParams.equipment_id && dataParams.asset_type == 'equipment'"
										v-bind="attributes" v-on="events" />
								</template>
							</v-select>
							<v-select label="plate_no" :options="vehicleList.data" :reduce="item => item.id"
								placeholder="-Select-" v-model="dataParams.vehicle_id"
								v-if="dataParams.asset_type == 'vehicle'">
								<template #search="{ attributes, events }" v-if="dataParams.asset_type == 'vehicle'">
									<input class="vs__search"
										:required="!dataParams.vehicle_id && dataParams.asset_type == 'vehicle'"
										v-bind="attributes" v-on="events" />
								</template>
							</v-select>
						</CCol>
					</CRow>
					<CRow class="mb-3 mt-3">

						<CCol lg="12">
							<label>
								Renewal Task
								<span class="text-danger">*</span>
							</label>
							<v-select label="setting_name" :options="taskList.data" :reduce="item => item.id"
								placeholder="-Select- " v-model="dataParams.renewal_task_id">
								<template #search="{ attributes, events }">
									<input class="vs__search" :required="!dataParams.renewal_task_id" v-bind="attributes"
										v-on="events" />
								</template>
							</v-select>
						</CCol>
					</CRow>
					<CRow>
						<CCol lg="12">
							<div class="form-group">
								<label>Due Date
									<span class="text-danger">*</span>
								</label>
								<!-- <Datetime format="MM-DD-YYYY" 
							v-model="dataParams.delivery_date" 
							:required="true" 
							placeholder="Delivery date"></Datetime> -->

								<Datepicker input-class="form-control bg-white" v-model="dataParams.due_date" placeholder=""
									:typeable="true" required>
								</Datepicker>
							</div>
						</CCol>
					</CRow>
					<CRow>
						<CCol lg="12">
							<label>
								Notified Email <span class="text-danger">*</span>
							</label>
							<CInput placeholder="" v-model="dataParams.email" required />
							<div class="text-info mb-2" style="margin-top: -15px; font-size:11px">Enter multiple email
								addresses, separated by comma.</div>
						</CCol>
					</CRow>
					<hr />
					<CRow>
						<CCol lg="6">
							<label style="font-size:13px">
								Time Due Soon Threshold <span class="text-danger">*</span>
								<i class="fa fa-question-circle ml-2"
									title="The amount of time in advance you consider this reminder to be due soon. (For example: Every 2 weeks)"></i>
							</label>
							<CInput placeholder="Enter number" 
							v-model="dataParams.time" 
							@keypress="isNumber($event)"
							required />
						</CCol>
						<CCol lg="6">
							<label style="font-size:13px">
								&nbsp;
							</label>
							<v-select label="setting_name" :options="[
								{
									id: 'days',
									setting_name: 'Days'
								},
								{
									id: 'weeks',
									setting_name: 'Weeks'
								},
								{
									id: 'months',
									setting_name: 'Months'
								},
								{
									id: 'years',
									setting_name: 'Years'
								}
							]" :reduce="item => item.id" v-model="dataParams.treshold_unit">
								<template #search="{attributes, events}">
											<input
												class="vs__search"
												:required="!dataParams.treshold_unit"
												v-bind="attributes"
												v-on="events"
											/>
							</template>

							</v-select>
						

						</CCol>
					</CRow>

					<span class="text-danger">{{ message }}</span>
					<div slot="footer" class="w-100">
						<CButton type="submit" style="border-radius: .2rem; color: white;" color="info"
							class="ml-1 mr-1 float-right">
							Save
						</CButton>
						<CButton style="border-radius: .2rem; color: white;" color="dark" class="ml-1 mr-1 float-right"
							@click="formModal = false">
							Cancel
						</CButton>


					</div>

				</form>
				<div slot="footer" class="w-100">
				</div>
			</CModal>

			<!-- VIEWING DETAILS -->
			<CModal title="Asset Renewal Reminder" :show.sync="showViewModal" color="info" size="md">
				<CRow class="mb-2 pr-4 pl-4">

					<CCol lg="12">
						<h4 class="text-capitalize">{{ dataParams.asset_type }} Renewal - {{ dataParams.asset_no_label }} </h4>
					</CCol>


				</CRow>
				<!-- <CRow class="mb-2">
	        		<CCol lg="4">
	        			Asset Type:
	        		</CCol>
	        		<CCol lg="8">
	        			{{dataParams.asset_type}}
	        		</CCol>
	        	</CRow> -->
				<!-- <CRow class="mb-2">
	        		<CCol lg="4" v-if="dataParams.asset_type">
	        			Plate No.:
	        		</CCol>
	        		<CCol lg="4" v-else>
	        			Asset No.:
	        		</CCol>
	        		<CCol lg="8">
	        			{{dataParams.asset_no_label}}
	        		</CCol>
	        	</CRow> -->
				<CRow class="mb-2 pr-4 pl-4">
					<CCol lg="6">
						Renewal Task:
					</CCol>
					<CCol lg="6">
						{{ dataParams.renewal_task_id_label }}
					</CCol>
				</CRow>
				<CRow class="mb-2 pr-4 pl-4">
					<CCol lg="6">
						Due Date:
					</CCol>
					<CCol lg="6">
						{{ dataParams.due_date_label }}
					</CCol>
				</CRow>
				<CRow class="mb-2 pr-4 pl-4">
					<CCol lg="6">
						Due Soon Threshold:
					</CCol>
					<CCol lg="6">
						{{ dataParams.time }} {{ dataParams.treshold_unit }}
						<!-- {{dataParams.treshold_unit}} -->
					</CCol>

				</CRow>
				<CRow class="mb-2 pr-4 pl-4">
					<CCol lg="6">
						Notified Email:
					</CCol>
					<CCol lg="6">
						{{ dataParams.email }}
					</CCol>
				</CRow>


				<div slot="footer" class="w-100">


				</div>
				<div slot="footer" class="w-100"></div>
			</CModal>
		</CCard>
	</div>
</template>
<script>
import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';
import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import moment from 'moment';

export default {
	mounted() {
		this.getData();
		// this.getDataOverDue();
		// this.getDataDueSoon();
		this.getVehicleList();
		this.getEquipment();
		this.getFurnishing();
		this.getRenewalTask();
	},
	data() {
		return {
			config,
			title: '',
			formModal: false,
			showViewModal: false,
			dataListAll: [],
			// dataListDueSoon: [],
			// dataListOverDue: [],
			depotName: "",
			email: "",
			dataParams: {
				asset_type: "vehicle",
				vehicle_id: "",
				furnishing_id: "",
				equipment_id: "",
				email: "",
				treshold_unit: "",
				time: "",
				renewal_task_id: "",
				due_date: "",
			},
			fields: [
				{
					key: 'asset_type',
					label: 'Asset Type'
				},
				{
					key: 'asset_no_label',
					label: 'Asset No./Plate No.'
				},
				{
					key: 'type',
					label: 'Type'
				},
				{
					key: 'renewal_task_id_label',
					label: 'Renewal Task'
				},
				{
					key: 'status',
					label: 'Status'
				},
				{
					key: 'due_date_label',
					label: 'Due Date'
				},
				{
					key: 'action',
					label: 'Action',
					_style: { width: '20px' }
				}
			],
			currentPageAll: 1,
			// currentPageDueSoon: 1,
			// currentPageOverDue: 1,
			editMode: false,
			selectedRow: {},
			showCollapse: false,
			isLoading: false,
			filter: {
				asset_type: "",
				vehicle_id: "",
				furnishing_id: "",
				equipment_id: "",
				renewal_task_id: "",
				date_from: "",
				date_to: "",
			},

			depotList: {
				data: []
			},
			userTypeList: {
				data: []
			},
			companyList: {
				data: []
			},
			vehicleList: {
				data: []
			},
			equipmentList: {
				data: []
			},
			furnishingList: {
				data: []
			},
			taskList: {
				data: []
			},
			message: ""
		}
	},
	name: 'Tables',
	components: { Search, Datepicker, vSelect },
	methods: {
		getBadge(status) {
			return status === 'active' ? 'success'
				: status === 'inactive' ? 'danger' : 'primary'
		},
		// updatePageOverDue(data){
		// 	this.currentPageOverDue = data;
		// 	this.getDataOverDue();
		// }, 
		updatePageAll(data) {
			this.currentPageAll = data;
			this.getData();
		},
		// updatePageDueSoon(data){
		// 	this.currentPageDueSoon = data;
		// 	this.getDataDueSoon();
		// }, 

		toggleModal() {
			this.formModal = !this.formModal;
			this.title = "Add User";

		},

		submit() {
			var ax = {};
			this.message = "";
			var temp_date = this.dataParams.due_date;
			this.dataParams.due_date = moment(this.dataParams.due_date).startOf('day').unix();
			// var current_date = moment(new Date()).startOf('day').unix();
			// if (current_date >= this.dataParams.due_date) {
			// 	this.message = "Can't select today's date or previous dates";
			// 	this.dataParams.due_date = temp_date;
			// 	return;
			// }
			if (this.dataParams.furnishing_id == "") {
				this.dataParams.furnishing_id = null
			}
			if (this.dataParams.vehicle_id == "") {
				this.dataParams.vehicle_id = null
			}
			if (this.dataParams.equipment_id == "") {
				this.dataParams.equipment_id = null
			}
			var time_next_due = moment.unix(this.dataParams.due_date);
			var pre_due_date = moment(time_next_due).subtract(this.dataParams.time, this.dataParams.treshold_unit)
			pre_due_date = pre_due_date.unix();
			this.dataParams.pre_due_date = pre_due_date;

			if (this.editMode) {
				ax = axios.put(config.api_path + "/renewalReminder/" + this.selectedRow.id, this.dataParams)
			}
			else {
				ax = axios.post(config.api_path + "/renewalReminder", this.dataParams)
			}
			ax.then(response => {
				if (response.data.status == "failed") {
					this.message = response.data.message;
					this.dataParams.due_date = temp_date;
					return;
				}
				this.message = ""
				// 		if(response.data.status == 'failed'){
				// 			Swal.fire({
				// 	title: 'Error!',
				// 	text: response.data.message,
				// 	icon: 'error', 
				// })
				// return;
				// 		}
				var text = 'Asset Renewal reminder successfully added!';
				if (this.editMode) {
					text = 'Asset Renewal reminder successfully updated!';
				}
				Swal.fire({
					title: 'Success!',
					text,
					icon: 'success',
				})
				this.formModal = false
				this.editMode = false;
				this.dataParams = {
					asset_type: "vehicle",
					vehicle_id: "",
					furnishing_id: "",
					equipment_id: "",
					email: "",
					treshold_unit: "",
					time: "",
					renewal_task_id: "",
					due_date: "",
				}
				this.getData();
				// this.getDataOverDue();
				// this.getDataDueSoon();
			})
		},

		search(event) {
			this.filter = event;
			this.getData();
			// this.getDataOverDue();
			// this.getDataDueSoon();
		},

		getData() {

			this.isLoading = true;

			var renewal_task_id = this.filter.renewal_task_id;
			if (renewal_task_id == "all" || renewal_task_id == null || renewal_task_id == "") {
				renewal_task_id = ""
			}

			var vehicle_id = this.filter.vehicle_id;
			if (vehicle_id == "all" || vehicle_id == null || vehicle_id == "") {
				vehicle_id = "";
			}

			var furnishing_id = this.filter.furnishing_id;
			if (furnishing_id == "all" || furnishing_id == null || furnishing_id == "") {
				furnishing_id = "";
			}

			var equipment_id = this.filter.equipment_id;
			if (equipment_id == "all" || equipment_id == null || equipment_id == "") {
				equipment_id = "";
			}

			var asset_type = this.filter.asset_type;
			if (asset_type == "all" || asset_type == null || asset_type == "") {
				asset_type = "";
				vehicle_id = ""
				furnishing_id = ""
				equipment_id = ""

			}
			else {
				if (asset_type == "vehicle") {
					furnishing_id = ""
					equipment_id = ""
				}
				if (asset_type == "furnishing") {
					vehicle_id = ""
					equipment_id = ""
				}
				if (asset_type == "equipment") {
					vehicle_id = ""
					furnishing_id = ""
				}
			}

			var vehicle_type_id = this.filter.vehicle_type_id;
			if (vehicle_type_id == "all" || vehicle_type_id == null) {
				vehicle_type_id = "";
			}

			var date_from = this.filter.date_from;
			if (date_from != "") {
				date_from = moment(date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
			}

			var date_to = this.filter.date_to;
			if (date_to != "") {
				date_to = moment(date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
				date_to = date_to + 86399;
			}

			axios.get(config.api_path + '/renewalReminder?status=&renewal_task_id=' + renewal_task_id + '&asset_type=' + asset_type + '&due_date_from=' + date_from + '&due_date_to=' + date_to + '&vehicle_id=' + vehicle_id + '&furnishing_id=' + furnishing_id + '&equipment_id=' + equipment_id + '&page=' + this.currentPageAll + '&limit=20')
				.then(response => {
					const {data} = response.data;
					this.dataListAll = response.data;
					if(!Array.isArray(data)) {
						const holder = [];
						for (const key in data) {
							if (Object.hasOwnProperty.call(data, key)) {
								const element = data[key];
								holder.push(element)
							}
						}
						this.dataListAll.data = [...holder]
					}
					this.dataListAll.data = this.dataListAll.data.map((value, index)=>{
						
						console.log('AAAA', value)
						value.vehicle_maker = "N/A";
						value.vehicle_type = "N/A";

						if (value.asset_type == "vehicle") {
							value.type = value.vehicle.vehicle_type.setting_name;
							value.asset_no_label = value.vehicle.plate_no;
						}
						else if (value.asset_type == "furnishing") {
							value.type = value.furnishing.type;
							value.asset_no_label = value.furnishing.asset_no;
						}
						else if (value.asset_type == "equipment") {
							value.type = value.equipment.type;
							value.asset_no_label = value.equipment.asset_no;
						}

						value.renewal_task_id_label = "N/A";
						if (value.renewal_task_id && value.renewal_task_id != "") {
							value.renewal_task_id_label = value.renewal_task.setting_name;
						}

						value.due_date_label = "N/A";
						if (value.due_date && value.due_date != "") {
							value.due_date_label = moment.unix(value.due_date).format('MMMM DD, YYYY')
						}
						var unit = "days";
						if (value.treshold_unit == "weeks") {
							unit = "weeks";
						}
						else if (value.treshold_unit == "months") {
							unit = "months";
						}
						else if (value.treshold_unit == "years") {
							unit = "years";
						}
						value.notify_date = moment.unix(value.due_date).subtract(value.time, unit).format('MMMM DD, YYYY');


						var due_date = moment.unix(value.due_date);
						var today = moment(new Date()).startOf('day').unix();
						var week_before = moment.unix(value.due_date).subtract(value.time, unit).unix();
						var renewal_badge = null;
						// var renewal_text = moment.unix(week_before).format('MMMM DD, YYYY');
						var remaining_time = value.due_date - today;

						var today = moment(new Date()).startOf('day').unix();
						var renewal_badge = null;

						var renewal_text = 'Due ' + moment.unix(value.due_date).fromNow(); //remaining_time


						// subtract 1 to match fromNow() function
						const diff_days = (moment.unix(value.due_date).diff(moment.unix(today), 'days')) - 1;
						if (diff_days < 0)
							renewal_badge = 'danger';
						else if (diff_days >= 0 && diff_days <= 7)
							renewal_badge = 'warning';
						else
							renewal_badge = 'info';

						// console.log(renewal_text, diff_days)
						value.renewal_badge = renewal_badge;
						value.renewal_text = renewal_text;

						return value;
					});
				})
				.catch(err => {
					console.error(err)
					// this.isLoading = false;
				})
				.finally(fin => {
					// console.log(fin)
					this.isLoading = false;
				})
		},




		updateModal(item) {
			this.selectedRow = item;
			this.dataParams = item;
			this.dataParams.due_date = moment.unix(item.due_date).format('MMMM DD, YYYY')
			this.formModal = true;
			this.editMode = true;
			this.title = "Update User";
		},

		viewModal(item) {

			this.selectedRow = item;
			this.dataParams = item;
			this.showViewModal = true;

		},

		deleteRow(item) {
			Swal.fire({
				icon: 'warning',
				title: 'Are you sure you want to delete this Renewal reminder?',
				showCancelButton: true,
				confirmButtonText: `Yes`,
			}).then((result) => {
				if (result.isConfirmed) {
					axios.delete(config.api_path + '/renewalReminder/' + item.id)
						.then(response => {
							this.getData();
							// this.getDataOverDue();
							// this.getDataDueSoon();
							Swal.fire({
								title: 'Success!',
								text: "Renewal reminder successfully deleted",
								icon: 'success',
							})
						})
				}
			})
		},

		updateGender(data) {
			this.dataParams.gender = data;
		},



		getVehicleList() {

			axios.get(config.api_path + '/reference/vehicle-list', {
				params: {

				}
			})
				.then(response => {
					this.vehicleList = response.data;
				})

		},

		getEquipment() {

			axios.get(config.api_path + '/reference/equipment-list')
				.then(response => {
					this.equipmentList = response.data;
					this.equipmentList.data = this.equipmentList.data.map((value, index) => {
						value.value = value.id;
						value.label = value.asset_no;
						return value;
					})
				})

		},

		getFurnishing() {

			axios.get(config.api_path + '/reference/furnishing-list')
				.then(response => {
					this.furnishingList = response.data;
					this.furnishingList.data = this.furnishingList.data.map((value, index) => {
						value.value = value.id;
						value.label = value.asset_no;
						return value;
					})
				})

		},

		getRenewalTask() {

			axios.get(config.api_path + '/reference/settings-list', {
				params: {
					setting_type: 'renewal_task'
				}
			})
				.then(response => {
					this.taskList = response.data;
				})

		},
		sendEmail(item) {
			Swal.fire({
				title: 'Sending email',
				html: 'Please wait.',
				allowOutsideClick:false,
				allowEscapeKey:false
			});
			Swal.showLoading();
			axios.post(config.api_path + '/send_email/' + item.id)
			.then(response => {
				Swal.fire({
					title: 'Success!',
					text: 'Successfully notified ' + response.data.message,
					icon: 'success',
				})
			})
		}
	}
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-4"},[_c('CRow',{staticClass:"mb-0"},[_c('CCol',{attrs:{"lg":"2"}},[_c('Datepicker',{attrs:{"input-class":"form-control","placeholder":"Date from"},model:{value:(_vm.date_from),callback:function ($$v) {_vm.date_from=$$v},expression:"date_from"}})],1),_c('CCol',{attrs:{"lg":"2"}},[_c('Datepicker',{attrs:{"input-class":"form-control","placeholder":"Date To"},model:{value:(_vm.date_to),callback:function ($$v) {_vm.date_to=$$v},expression:"date_to"}})],1),_c('CCol',{attrs:{"lg":"2"}},[_c('v-select',{attrs:{"label":"setting_name","options":[
            {
              id: 'vehicle',
              setting_name: 'Vehicle'
            },
            {
              id: 'furnishing',
              setting_name: 'Furnishing'
            },
            {
              id: 'equipment',
              setting_name: 'Equipment'
            }
          ],"reduce":function (item) { return item.id; },"placeholder":"Asset Type"},model:{value:(_vm.asset_type),callback:function ($$v) {_vm.asset_type=$$v},expression:"asset_type"}})],1),_c('CCol',{attrs:{"lg":"2"}},[_c('v-select',{attrs:{"label":"setting_name","options":_vm.taskList.data,"reduce":function (item) { return item.id; },"placeholder":"Task"},model:{value:(_vm.renewal_task_id),callback:function ($$v) {_vm.renewal_task_id=$$v},expression:"renewal_task_id"}})],1),_c('CCol',{attrs:{"lg":"2"}}),_c('CCol',{staticClass:"float-right",attrs:{"lg":"2"}},[_c('CButton',{attrs:{"size":"sm","block":"","color":"info"},on:{"click":_vm.search}},[_c('i',{staticClass:"icon-magnifier"}),_vm._v(" Search")])],1)],1),_c('CRow',{staticClass:"mt-2"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }